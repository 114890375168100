:root {
  font-size: 12px !important;
}

/** Override dropdown menu position for scrolled tables to fix overflow */
.availabilities-table,
.bookings-table {
  transform: translate(0);
}
.availabilities-table .lynkco-dropdown-item-wrapper,
.bookings-table .lynkco-dropdown-item-wrapper {
  position: fixed;
  right: 1rem;
}
/** Override pagination border */
.availabilities-table .pagination-container > nav,
.bookings-table .pagination-container > nav {
  border-top: none;
}
